<template>
  <div id="partnership">
    <div class="topbar topbar-transparent">
      <div class="container">
        <div class="brand">
          <router-link to="/">
            <img src="/nedcollege/NEDCOLLEGE_logo_light.svg" alt="" />
          </router-link>
        </div>
        <p v-html="lang.topbar" class="align-self-center" />
      </div>
    </div>

    <ImageContainer background class="page-header-partnership" fileName="partnership-header">
      <div class="container text-white">
        <h1 v-html="lang.header.title" />
        <h4>{{ lang.header.subtitle }}</h4>
      </div>
    </ImageContainer>

    <div class="container my-6">
      <div class="row">
        <div class="col-md-5">
          <h2 class="text-blue1 mb-4" v-html="lang.about.title" />
        </div>
        <div class="col-md-7">
          <p class="text-blue1">
            <b>{{ lang.about.subtitle }}</b>
          </p>
          <p>{{ lang.about.text }}</p>
        </div>
      </div>
    </div>

    <CertificationsSection class="bg-blue4 py-6">
      <h2 v-html="lang.accreditations" class="text-center" />
    </CertificationsSection>

    <div class="bg-blue1 py-6">
      <div class="container" id="media">
        <div class="row">
          <div class="col-md-4 text-white">
            <h2 class="mb-4" v-html="lang.media.title" />
            <p>
              <b>{{ lang.media.subtitle }}</b>
            </p>
            <p>{{ lang.media.text }}</p>
          </div>
          <div class="offset-md-1 col-md-7">
            <div class="d-grid grid-2-cols gap-3">
              <a
                :href="item.link"
                class="card card-partnership card-blue2 m-0"
                v-for="(item, ix) of lang.media.items"
                :key="ix"
              >
                <div class="card-header">
                  <h5>{{ item.title }}</h5>
                </div>
                <div class="card-body">{{ item.description }}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-blue1 py-6">
      <div class="container" id="brochure">
        <div class="row">
          <div class="col-md-4 text-white d-flex flex-column">
            <h3 class="mb-4 text-green3" v-html="lang.brochure.title" />
            <p class="mb-5">{{ lang.brochure.subtitle }}</p>

            <a class="mt-auto mb-6 mb-md-0 text-white" href="#media">
              <i class="fa fa-arrow-up mr-2 text-green2"></i>
              {{ lang.back_to_top }}
            </a>
          </div>
          <div class="offset-md-1 col-md-7">
            <div class="vertical-separator">
              <div class="d-grid grid-2-cols gap-4">
                <a :href="item.link" v-for="(item, ix) of lang.brochure.items" :key="ix">
                  <ImageContainer :fileName="item.image" class="mb-2" />
                  <small>{{ item.title }}</small>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-blue1 py-6">
      <div class="container" id="pricelist">
        <div class="row">
          <div class="col-md-4 text-white d-flex flex-column">
            <h3 class="mb-4 text-green3" v-html="lang.pricelist.title" />
            <p class="mb-5">{{ lang.pricelist.subtitle }}</p>

            <a class="mt-auto mb-6 mb-md-0 text-white" href="#media">
              <i class="fa fa-arrow-up mr-2 text-green2"></i>
              {{ lang.back_to_top }}
            </a>
          </div>
          <div class="offset-md-1 col-md-7">
            <div class="vertical-separator">
              <div class="d-grid grid-2-cols gap-4">
                <a :href="item.link" v-for="(item, ix) of lang.pricelist.items" :key="ix">
                  <ImageContainer :fileName="item.image" class="mb-2" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-blue1 py-6">
      <div class="container" id="calendar">
        <div class="row">
          <div class="col-md-4 text-white d-flex flex-column">
            <h3 class="mb-4 text-green3" v-html="lang.calendar.title" />
            <p class="mb-5">{{ lang.calendar.subtitle }}</p>

            <a class="mt-auto mb-6 mb-md-0 text-white" href="#media">
              <i class="fa fa-arrow-up mr-2 text-green2"></i>
              {{ lang.back_to_top }}
            </a>
          </div>
          <div class="offset-md-1 col-md-7">
            <div class="vertical-separator">
              <div class="d-grid grid-2-cols gap-4">
                <a :href="item.link" v-for="(item, ix) of lang.calendar.items" :key="ix">
                  <ImageContainer :fileName="item.image" class="mb-2" />
                  <small>{{ item.title }}</small>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-blue1 py-6">
      <div class="container" id="videogallery">
        <div class="row">
          <div class="col-md-4 text-white d-flex flex-column">
            <h3 class="mb-4 text-green3" v-html="lang.videos.title" />
            <p class="mb-5">{{ lang.videos.subtitle }}</p>

            <a class="mt-auto mb-6 mb-md-0 text-white" href="#media">
              <i class="fa fa-arrow-up mr-2 text-green2"></i>
              {{ lang.back_to_top }}
            </a>
          </div>
          <div class="offset-md-1 col-md-7">
            <div class="vertical-separator">
              <div class="d-grid grid-2-cols gap-4">
                <a :href="item.link" v-for="(item, ix) of lang.videos.items" :key="ix">
                  <ImageContainer :fileName="item.image" class="mb-2" />
                  <small>{{ item.title }}</small>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-blue1 py-6">
      <div class="container" id="imagegallery">
        <div class="row">
          <div class="col-md-4 text-white d-flex flex-column">
            <h3 class="mb-4 text-green3" v-html="lang.images.title" />
            <p class="mb-5">{{ lang.images.subtitle }}</p>

            <a class="mt-auto mb-6 mb-md-0 text-white" href="#media">
              <i class="fa fa-arrow-up mr-2 text-green2"></i>
              {{ lang.back_to_top }}
            </a>
          </div>
          <div class="offset-md-1 col-md-7">
            <div class="vertical-separator mb-5">
              <h4 class="text-white mb-4">{{ lang.images.dublin.title }}</h4>

              <a :href="lang.images.dublin.link">
                <div class="d-grid grid-3-cols gap-3">
                  <ImageContainer
                    v-for="(item, ix) of lang.images.dublin.items"
                    :key="ix"
                    :fileName="item"
                  />
                </div>
              </a>
            </div>
            <div class="vertical-separator">
              <h4 class="text-white mb-4">{{ lang.images.limerick.title }}</h4>

              <a :href="lang.images.limerick.link">
                <div class="d-grid grid-3-cols gap-3">
                  <ImageContainer
                    v-for="(item, ix) of lang.images.limerick.items"
                    :key="ix"
                    :fileName="item"
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-blue1 py-6">
      <div class="container" id="graphicmaterial">
        <div class="row">
          <div class="col-md-4 text-white d-flex flex-column">
            <h3 class="mb-4 text-green3" v-html="lang.material.title" />
            <p class="mb-5">{{ lang.material.subtitle }}</p>

            <a class="mt-auto mb-6 mb-md-0 text-white" href="#media">
              <i class="fa fa-arrow-up mr-2 text-green2"></i>
              {{ lang.back_to_top }}
            </a>
          </div>
          <div class="offset-md-1 col-md-7">
            <div class="vertical-separator">
              <div class="d-grid grid-2-cols gap-4">
                <a :href="item.link" v-for="(item, ix) of lang.material.items" :key="ix">
                  <ImageContainer :fileName="item.image" class="mb-2" />
                  <small>{{ item.title }}</small>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer>
      <ImageContainer background fileName="footer-partnership">
        <div class="container py-6 text-white text-center">
          <h3 v-html="lang.footer.title" />
          <p>{{ lang.footer.subtitle }}</p>

          <button class="btn btn-green2" @click="sendForm">
            {{ lang.footer.button }}
          </button>

          <div class="row align-items-center mt-5 gap-y-3">
            <div class="col-md-4 text-md-left">
              <img src="/nedcollege/NEDCOLLEGE_logo_light.svg" height="50" />
            </div>
            <div class="col-md-4">
              <div class="btn-group justify-content-center">
                <a
                  href="https://www.instagram.com/nedcollege/"
                  target="_blank"
                  class="btn btn-sm btn-circle btn-white"
                >
                  <i class="fa-brands fa-instagram"></i>
                </a>
                <a
                  href="https://www.facebook.com/NEDCollege/"
                  target="_blank"
                  class="btn btn-sm btn-circle btn-white"
                >
                  <i class="fa-brands fa-facebook"></i>
                </a>
                <a
                  href="https://www.youtube.com/user/nedtcschool"
                  target="_blank"
                  class="btn btn-sm btn-circle btn-white"
                >
                  <i class="fa-brands fa-youtube"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/nedcollege"
                  target="_blank"
                  class="btn btn-sm btn-circle btn-white"
                >
                  <i class="fa-brands fa-linkedin"></i>
                </a>
                <a
                  href="https://www.tiktok.com/@nedcollege"
                  target="_blank"
                  class="btn btn-sm btn-circle btn-white"
                >
                  <i class="fa-brands fa-tiktok"></i>
                </a>
              </div>
            </div>
            <div class="col-md-4 text-md-right">
              <router-link to="/">
                <h4>ned.ie</h4>
              </router-link>
            </div>
            <div class="col-12 mt-2">
              <small>© 2023 NED College</small>
            </div>
          </div>
        </div>
      </ImageContainer>
    </footer>
  </div>
</template>

<script>
import CertificationsSection from "@/components/CertificationsSection.vue";

export default {
  components: {
    CertificationsSection,
  },
  computed: {
    lang() {
      return this.$lang();
    },
  },
  methods: {
    sendForm() {
      this.$router.push({ name: "contact_us" });
    },
  },
};
</script>
